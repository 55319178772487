import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SuccessComponent.css";
import logo from "../assets/images/logo1000gif.gif";

interface SuccessComponentProps {
  type: "success" | "cancel"; // Definiamo il tipo di parametro
}

const SuccessComponent: React.FC<SuccessComponentProps> = ({ type }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Funzione per ottenere i parametri dall'URL
  const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      nocSessionId: params.get("noc_session_id"),
      stripeSessionId: params.get("stripe_session_id"),
      ticketType: params.get("type"),
    };
  };

  useEffect(() => {
    if (type === "cancel") {
      setError("Pagamento annullato.");
      setLoading(false);
      return;
    }

    // Otteniamo i parametri dall'URL
    const { nocSessionId, stripeSessionId, ticketType } = getQueryParams();

    // Se non abbiamo i parametri necessari, mostriamo un errore
    if (!nocSessionId || !stripeSessionId || !ticketType) {
      setError("Parametri mancanti nell'URL.");
      setLoading(false);
      return;
    }

    // Chiama l'API di pagamento
    const callPaymentAPI = async () => {
      try {
        const response = await axios.post(
          "https://noc-api-dev.azurewebsites.net/payment/create-tickets",
          {
            NocSessionId: nocSessionId,
            StripeSessionId: stripeSessionId,
            Type: ticketType,
          }
        );
        if (response.status === 200) {
          setLoading(false);
        } else {
          throw new Error("Errore nella risposta dall'API.");
        }
      } catch (err) {
        setError("Errore durante la chiamata API.");
        // Effettua il redirect alla pagina /cancel
        window.location.href = "/cancel";
      }
    };

    callPaymentAPI();
  }, [type]);

  const handleCloseBrowser = () => {
    window.location.href = "nocapp://closewebbrowser";
  };

  if (loading) {
    return (
      <div className="app-header text-dark text-center">
        <img src={logo} alt="Logo" className="app-logo" />
        <h1>Caricamento in corso...</h1>
        <p>Stiamo elaborando il tuo acquisto, attendere prego.</p>
      </div>
    );
  }

  if (error || type === "cancel") {
    return (
      <div className="app-header text-dark text-center">
        <img src={logo} alt="Logo" className="app-logo" />
        <h1>{type === "cancel" ? "Pagamento annullato" : "Errore"}</h1>
        <p>{type === "cancel" ? "Il pagamento è stato annullato." : error}</p>
        <button onClick={handleCloseBrowser}>Chiudi</button>
      </div>
    );
  }

  return (
    <div className="app-header text-dark py-1 text-center">
      <img src={logo} alt="Logo" className="app-logo" />
      <h1>Acquisto completato!</h1>
      <p>
        Grazie per il tuo acquisto. Abbiamo inviato una conferma alla tua app e
        al tuo indirizzo email.
      </p>
      <button onClick={handleCloseBrowser}>Chiudi</button>
    </div>
  );
};

export default SuccessComponent;
